import { useRef, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type Supplier,
  type OrderRequestLineItem
} from '@amici/myamici-api-client'
import {
  BsChevronDown,
  BsChevronUp,
  BsExclamationTriangle
} from 'react-icons/bs'
import classNames from 'classnames'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import SupplierStatusIndicator from '../../purchasing/components/SupplierStatusIndicator'
import OrderRequestLineItemCard from './OrderRequestLineItemCard'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import styles from '../assets/scss/OrderRequestSpendGroupedView.module.scss'
import MaTooltip from '../../common/components/MaTooltip'

function ShortSummary ({
  lineItems,
  supplier,
  currency,
  isExpanded,
  updatesRequired
}: Readonly<{
  lineItems: OrderRequestLineItem[]
  supplier: Supplier
  currency: string
  isExpanded: boolean
  updatesRequired: boolean
}>): ReactElement {
  const { t } = useTranslation()
  const {
    getSupplierItems,
    getSupplierTotal,
    getSupplierSurcharges,
    getSupplierSubtotal
  } = useOrderRequestSummary(lineItems)

  const surcharges = getSupplierSurcharges(supplier, currency)

  return (
    <div className={styles['spend-summary-entries']}>
      <p>
        <span>
          {updatesRequired && !isExpanded && (
            <MaTooltip
              text={t('order_request.updates_required.tooltip')}
              trigger={['hover', 'focus']}
            >
              <BsExclamationTriangle
                className={styles['warning-icon']}
                aria-label={t('order_request.updates_required.tooltip')}
              />
            </MaTooltip>
          )}
          {t('order_request.spend.items', {
            count: getSupplierItems(supplier, currency).length
          })}
        </span>
        <strong>
          {t('common.price', {
            price: getSupplierTotal(supplier, currency),
            currency
          })}
        </strong>
      </p>

      {!isExpanded && (
        <>
          <p>
            <span>{t('order_request.spend.est_surcharges')}</span>
            <strong>
              {surcharges < 0
                ? t('order_request.spend.unknown')
                : t('common.price', {
                    price: surcharges,
                    currency
                  })}
            </strong>
          </p>
          <p>
            <strong>
              {t('order_request.spend.supplier_subtotal', {
                supplier: supplier.name
              })}
            </strong>
            <strong>
              {t('common.price', {
                price: getSupplierSubtotal(supplier, currency),
                currency
              })}
            </strong>
          </p>
        </>
      )}
    </div>
  )
}

function SupplierSummary ({
  lineItems,
  supplier,
  currency
}: Readonly<{
  lineItems: OrderRequestLineItem[]
  supplier: Supplier
  currency: string
}>): ReactElement {
  const { t } = useTranslation()
  const {
    getSupplierMinOrderCharge,
    getSupplierDeliveryCharge,
    getSupplierSubtotal
  } = useOrderRequestSummary(lineItems)

  const minOrderCharge = getSupplierMinOrderCharge(supplier, currency)
  const deliveryCharge = getSupplierDeliveryCharge(supplier, currency)

  return (
    <div className={styles['spend-summary-entries']}>
      <p>
        <span>{t('order_request.spend.min_order_charge')}</span>
        <strong>
          {minOrderCharge < 0
            ? t('order_request.spend.unknown')
            : t('common.price', {
                price: minOrderCharge,
                currency
              })}
        </strong>
      </p>
      <p>
        <span>{t('order_request.spend.est_delivery')}</span>
        <strong>
          {deliveryCharge < 0
            ? t('order_request.spend.unknown')
            : t('common.price', {
                price: deliveryCharge,
                currency
              })}
        </strong>
      </p>
      <p>
        <strong>
          {t('order_request.spend.supplier_subtotal', {
            supplier: supplier.name
          })}
        </strong>
        <strong>
          {t('common.price', {
            price: getSupplierSubtotal(supplier, currency),
            currency
          })}
        </strong>
      </p>
    </div>
  )
}

function TotalSpend ({
  lineItems,
  currency
}: Readonly<{
  lineItems: OrderRequestLineItem[]
  currency: string
}>): ReactElement {
  const { t } = useTranslation()
  const { getTotalItemCount, getItemsTotal, getTotalSurcharges, getTotal } =
    useOrderRequestSummary(lineItems)

  return (
    <div className={styles['spend-summary-entries']}>
      <p>
        <span>
          {t('order_request.spend.items', {
            count: getTotalItemCount(currency)
          })}
        </span>
        <strong>
          {t('common.price', { price: getItemsTotal(currency), currency })}
        </strong>
      </p>
      <p>
        <span>{t('order_request.spend.total.est_surcharges')}</span>
        <strong>
          {t('common.price', { price: getTotalSurcharges(currency), currency })}
        </strong>
      </p>
      <p>
        <strong>{t('order_request.spend.total')}</strong>
        <strong>
          {t('common.price', {
            price: getTotal(currency),
            currency
          })}
        </strong>
      </p>
    </div>
  )
}

export interface OrderRequestSpendGroupedViewProps {
  lineItems: OrderRequestLineItem[]
  lineItemsWithMissingSpendCategories: OrderRequestLineItem[]
  lineItemsWithInactiveSpendCategories: OrderRequestLineItem[]
  expandedSupplierIds: string[]
  compactView: boolean
  canChange: boolean
  selectable: boolean
  selectedSuppliers: Map<string, CheckedState>
  selectedLineItemIds: string[]
  onToggleExpanded: (id: string) => void
  onEdit: (lineItem: OrderRequestLineItem) => void
  onRemove: (lineItem: OrderRequestLineItem) => void
  onItemSelectedChange: (
    lineItem: OrderRequestLineItem,
    checked: CheckedState
  ) => void
  onToggleSelectionBySupplier: (
    supplier: Supplier,
    checked: CheckedState
  ) => void
}

function OrderRequestSpendGroupedView ({
  lineItems,
  lineItemsWithMissingSpendCategories,
  lineItemsWithInactiveSpendCategories,
  expandedSupplierIds,
  compactView,
  canChange,
  selectable,
  selectedSuppliers,
  selectedLineItemIds,
  onToggleExpanded,
  onEdit,
  onRemove,
  onItemSelectedChange,
  onToggleSelectionBySupplier
}: Readonly<OrderRequestSpendGroupedViewProps>): ReactElement {
  const { t } = useTranslation()
  const currency = lineItems[0]?.line_item.currency ?? 'GBP'
  const { suppliers, getSupplierItems } = useOrderRequestSummary(lineItems)
  const ref = useRef(null)

  const isExpanded = (supplierId?: string | null): boolean =>
    expandedSupplierIds.includes(supplierId ?? '')

  const updatesRequired = (supplier: Supplier): boolean =>
    lineItems
      .filter(
        lineItem => lineItem.product_snapshot.supplier?.id === supplier.id
      )
      .some(
        lineItem =>
          lineItemsWithInactiveSpendCategories.includes(lineItem) ||
          lineItemsWithMissingSpendCategories.includes(lineItem)
      )

  return (
    <div
      className={classNames(styles['grouped-view'], {
        [styles.compact]: compactView
      })}
      ref={ref}
    >
      {suppliers.map(supplier => (
        <section
          key={supplier.id}
          className={classNames({ expanded: isExpanded(supplier.id) })}
        >
          <div className={styles['section-header-wrapper']}>
            {selectable && (
              <MaCheckbox
                aria-label={supplier.name ?? ''}
                checked={selectedSuppliers.get(supplier.id ?? '')}
                onCheckedChange={checked => {
                  onToggleSelectionBySupplier(supplier, checked)
                }}
              />
            )}

            <div
              className={styles['section-header']}
              onClick={() => {
                onToggleExpanded(supplier?.id ?? '')
              }}
            >
              <div className={styles['header-row']}>
                <div className={styles['supplier-name']}>
                  <h5>{supplier.name}</h5>

                  <SupplierStatusIndicator
                    className={styles['supplier-status']}
                    productId={
                      getSupplierItems(supplier, currency)[0].line_item.product
                        .id
                    }
                  />
                </div>

                <div className={styles['header-controls']}>
                  {!compactView && (
                    <ShortSummary
                      lineItems={lineItems}
                      supplier={supplier}
                      currency={currency}
                      isExpanded={isExpanded(supplier.id)}
                      updatesRequired={updatesRequired(supplier)}
                    />
                  )}

                  {isExpanded(supplier?.id) && <BsChevronUp size={16} />}
                  {!isExpanded(supplier?.id) && <BsChevronDown size={16} />}
                </div>
              </div>

              {compactView && (
                <div
                  className={classNames(
                    styles['header-row'],
                    styles['compact-summary']
                  )}
                >
                  <ShortSummary
                    lineItems={lineItems}
                    supplier={supplier}
                    currency={currency}
                    isExpanded={isExpanded(supplier.id)}
                    updatesRequired={updatesRequired(supplier)}
                  />
                </div>
              )}
            </div>
          </div>

          {isExpanded(supplier.id) && (
            <div>
              {getSupplierItems(supplier, currency).map(lineItem => (
                <OrderRequestLineItemCard
                  key={lineItem.line_item.id}
                  selected={selectedLineItemIds.includes(lineItem.line_item.id)}
                  selectable={selectable}
                  compactView={compactView}
                  canChange={canChange}
                  lineItem={lineItem}
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onSelectedChange={onItemSelectedChange}
                />
              ))}

              <div
                className={classNames(styles['supplier-summary-row'], {
                  compact: compactView
                })}
              >
                <SupplierSummary
                  lineItems={lineItems}
                  supplier={supplier}
                  currency={currency}
                />
              </div>
            </div>
          )}
        </section>
      ))}

      <section>
        <div
          className={classNames(styles['total-spend-row'], {
            compact: compactView
          })}
        >
          <h5>{t('order_request.spend.total')}</h5>

          <TotalSpend lineItems={lineItems} currency={currency} />
        </div>
      </section>
    </div>
  )
}

export default OrderRequestSpendGroupedView
