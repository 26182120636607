import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'
import styles from '../assets/scss/OrderRequest.module.scss'
import { BsExclamationTriangle } from 'react-icons/bs'

function OrderRequestAlert (): ReactNode {
  const { t } = useTranslation()

  return (
    <Alert
      aria-label={t('order_request.updates_required')}
      className={styles['spend-category-warning']}
      variant={'warning'}
    >
      <Alert.Heading className={styles.heading}>
        <span className={styles.icon}>
          <BsExclamationTriangle />
        </span>

        {t('order_request.updates_required')}
      </Alert.Heading>
    </Alert>
  )
}

export default OrderRequestAlert
