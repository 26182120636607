import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import OrderRequestSummaryPanel from '../components/OrderRequestSummaryPanel'
import OrderRequestDetailsPanel from '../components/OrderRequestDetailsPanel'
import OrderRequestSpendPanel from '../components/OrderRequestSpendPanel'
import OrderRequestHeader from '../components/OrderRequestHeader'
import OrderRequestActionMenu from '../components/OrderRequestActionMenu'
import OrderRequestStatusBadge from '../components/OrderRequestStatusBadge'
import { UpdatingLineItemsStateProvider } from '../components/OrderRequestUpdatingLineItemsProvider'
import styles from '../assets/scss/OrderRequest.module.scss'
import OrderRequestAlert from '../components/OrderRequestAlert'
import useOrderRequestPermissions from '../hooks/useOrderRequestPermissions'
import useAccounts from '../../common/hooks/useAccounts'

function OrderRequest (): ReactElement {
  const { orderRequestId } = useParams()
  const { data: orderRequest, isLoading: isLoadingOrderRequest } =
    useOrderRequest(orderRequestId)
  const {
    isLoading: isLoadingLineItems,
    linesWithMissingSpendCategories,
    linesWithInactiveSpendCategories
  } = useOrderRequestLineItems(orderRequestId)
  const { activeAccount } = useAccounts()
  const { canEdit } = useOrderRequestPermissions({
    orderRequest,
    account: activeAccount
  })

  const isLoading = isLoadingOrderRequest || isLoadingLineItems

  const lineItemUpdatesRequired =
    canEdit &&
    (linesWithMissingSpendCategories.length > 0 ||
      linesWithInactiveSpendCategories.length > 0)

  return (
    <Container fluid="auto" className="ma-page">
      <OrderRequestHeader />

      <div className={styles.content}>
        {isLoading && <LoadingSpinner />}

        {!isLoading && orderRequest && (
          <UpdatingLineItemsStateProvider>
            <div className={styles['summary-header']}>
              {orderRequest.order_status && (
                <OrderRequestStatusBadge status={orderRequest.order_status} />
              )}

              <h2>{orderRequest?.order_ref}</h2>

              <OrderRequestActionMenu orderRequest={orderRequest} />
            </div>

            <OrderRequestSummaryPanel orderRequest={orderRequest} />

            {lineItemUpdatesRequired && <OrderRequestAlert />}

            <OrderRequestSpendPanel orderRequest={orderRequest} />

            <OrderRequestDetailsPanel orderRequest={orderRequest} />
          </UpdatingLineItemsStateProvider>
        )}
      </div>
    </Container>
  )
}

export default OrderRequest
