import {
  type RefObject,
  useRef,
  useState,
  type ChangeEventHandler,
  type ChangeEvent,
  useEffect,
  type ReactElement,
  useId
} from 'react'
import {
  type SpendCategory,
  type OrderRequestLineItem,
  SpendCategoryStatusEnum
} from '@amici/myamici-api-client'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import MaConfirm from '../../common/components/MaConfirm'
import SpendCategoryFieldSelect from '../../spend-categories/components/SpendCategoryFieldSelect'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import styles from '../assets/scss/OrderRequestEditLineItemModal.module.scss'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import {
  LineItemsUpdatingStateActions,
  useUpdatingLineItemsStateDispatch
} from './OrderRequestUpdatingLineItemsProvider'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'

const FULL_VIEW_MIN_WIDTH_PX = 992
const MAX_QUANTITY = 1000000

export interface OrderRequestEditLineItemModalProps {
  orderRequestId: string
  lineItem?: OrderRequestLineItem
  onClose: () => void
}

function OrderRequestEditLineItemModal ({
  orderRequestId,
  lineItem,
  onClose
}: Readonly<OrderRequestEditLineItemModalProps>): ReactElement {
  const { t } = useTranslation()
  const id = useId()
  const { updateLineItem } = useOrderRequestLineItems(orderRequestId)
  const { data: spendCategoryFields } = useSpendCategories(true)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)
  const [selectedSpendCategories, setSelectedSpendCategories] = useState<
    SpendCategory[]
  >([])
  const [quantityInputValue, setQuantityInputValue] = useState<string>('')
  const ref = useRef<any>(null)
  const { showToastMessage } = useToastNotification()
  const dispatch = useUpdatingLineItemsStateDispatch()

  const compactView = !useMinWidthObserver(
    ref.current?.dialog as RefObject<Element>,
    FULL_VIEW_MIN_WIDTH_PX
  )

  const { trackEvent } = useOrderRequestTrackingEvent()

  useEffect(() => {
    if (lineItem) {
      setQuantityInputValue(lineItem.line_item.quantity.toString() ?? '')
      setSelectedSpendCategories(lineItem.line_item.spend_categories ?? [])
    }
  }, [lineItem])

  const validateQuantity = (quantity: number): boolean =>
    quantity > 0 && quantity <= MAX_QUANTITY

  const validateSpendCategories = (): boolean => {
    const activeSpendCategoryFieldIds =
      spendCategoryFields?.map(field => field.id) ?? []
    const selectedSpendCategoryFieldIds = selectedSpendCategories.map(
      spendCategory => spendCategory.field_id
    )
    const allActiveSpendCategoriesSelected = activeSpendCategoryFieldIds.every(
      id => selectedSpendCategoryFieldIds.includes(id)
    )
    const allSelectedSpendCategoriesActive = selectedSpendCategories.every(
      spendCategory => spendCategory.status === SpendCategoryStatusEnum.ACTIVE
    )
    return allActiveSpendCategoriesSelected && allSelectedSpendCategoriesActive
  }

  const quantity = parseInt(quantityInputValue || '0', 10)
  const subtotal = quantity * (lineItem?.product_price ?? 0)

  const isValid = validateQuantity(quantity) && validateSpendCategories()

  const product = lineItem?.line_item.product
  const packSize = product?.pack_size ?? 0
  const packSizeValue =
    ((product?.no_of_units ?? 0) > 1 ? `${product?.no_of_units} x ` : '') +
    packSize.toString()
  const packSizeUnit: string = product?.pack_size_unit ?? ''

  const handleQuantityChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    const newQuantity = parseInt(e.target.value, 10)

    if (isNaN(newQuantity)) {
      setQuantityInputValue('')
    } else if (validateQuantity(newQuantity)) {
      setQuantityInputValue(newQuantity.toString())
    }
  }

  const handleSpendCategoryChange = (spendCategory: SpendCategory): void => {
    setSelectedSpendCategories(spendCategories => [
      ...(spendCategories?.filter(
        ({ field_id: fieldId }) => fieldId !== spendCategory.field_id
      ) ?? []),
      spendCategory
    ])
  }

  const handleClose = (): void => {
    setQuantityInputValue('')
    setSelectedSpendCategories([])
    onClose()
  }

  const handleSubmit = async (): Promise<void> => {
    onClose()

    if (!lineItem) {
      return
    }

    const updatedLineItem: OrderRequestLineItem = {
      ...lineItem,
      line_item: {
        ...lineItem.line_item,
        issues: lineItem.line_item.issues ?? [],
        spend_categories: selectedSpendCategories,
        quantity
      }
    }

    trackEvent(orderRequestId, 'update_line_item')

    try {
      dispatch?.({
        type: LineItemsUpdatingStateActions.ADD_ACTIVE_EDITING,
        value: updatedLineItem.line_item.id
      })
      await updateLineItem(updatedLineItem)
      void refreshHistory()
    } catch {
      showToastMessage('danger', t('order_request.line_item.update.error'))
    } finally {
      dispatch?.({
        type: LineItemsUpdatingStateActions.REMOVE_ACTIVE_EDITING,
        value: updatedLineItem.line_item.id
      })
    }
  }

  return (
    <MaConfirm
      ref={ref}
      show={lineItem}
      size="lg"
      disabled={!isValid}
      title={t('order_request.line_item.edit_form.title')}
      confirmLabel={t('common.button.labels.save')}
      onClose={handleClose}
      onConfirm={handleSubmit}
      className={classNames(styles.form, { [styles.compact]: compactView })}
    >
      <h4>{product?.description}</h4>

      {lineItem && (
        <div className={styles.summary}>
          <table>
            <tbody>
              <tr>
                <th>{t('common.product.part_number')}</th>
                <td>{product?.part_number}</td>
              </tr>
              <tr>
                <th>{t('common.product.pack_size')}</th>
                <td>
                  {t('common.product.pack_size_value', {
                    packSizeValue,
                    packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                      count: +packSize
                    })
                  })}
                </td>
              </tr>
              <tr>
                <th>{t('common.label.price')}</th>
                <td>
                  {t('common.price', {
                    price: lineItem?.product_price,
                    currency: lineItem?.line_item.currency
                  })}
                </td>
              </tr>
            </tbody>
          </table>

          <div className={styles.quantity}>
            <Form.Group controlId={`quantity-${id}`}>
              <Form.Label className={styles.label}>
                {t('common.quantity')}
              </Form.Label>

              <Form.Control
                type="number"
                min={1}
                max={MAX_QUANTITY}
                value={quantityInputValue}
                isInvalid={!validateQuantity(quantity)}
                onChange={handleQuantityChange}
              />
            </Form.Group>

            {validateQuantity(quantity) && (
              <table>
                <tbody>
                  <tr>
                    <th>
                      <strong>{t('common.subtotal')}</strong>
                    </th>
                    <td>
                      {t('common.price', {
                        price: subtotal,
                        currency: lineItem?.line_item.currency
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}

      {selectedSpendCategories && (
        <SpendCategoryFieldSelect
          compactView={compactView}
          selectedSpendCategories={selectedSpendCategories}
          allowEmpty={false}
          excludeInactive={true}
          onValueChange={handleSpendCategoryChange}
        />
      )}
    </MaConfirm>
  )
}

export default OrderRequestEditLineItemModal
